import { StaticQuery, graphql } from 'gatsby';
import React from 'react';
import Img from 'gatsby-image';

const CalendarViewPhoto = () => (
  <StaticQuery
    query={graphql`
      query {
        photo: file(relativePath: { eq: "calendar-week-view.png" }) {
          childImageSharp {
            fluid(maxWidth: 400) {
              ...GatsbyImageSharpFluid_withWebp
              ...GatsbyImageSharpFluidLimitPresentationSize
            }
          }
        }
      }
    `}
    render={(data) => (
      <Img
        fluid={data.photo.childImageSharp.fluid}
        alt="Weekdays navigator"
        className="m-center"
        width="892"
        height="448"
      />
    )}
  />
);

export default CalendarViewPhoto;
