import React from 'react';
import { Link } from 'gatsby';

import Layout from '../components/layout';
import SEO from '../components/seo';
import HeroPost from '../components/Hero/hero-post';
import CalendarViewPhoto from '../components/Images/calendar-view';
import Code from '../components/MDX/CODE';
import H2 from '../components/MDX/H2';

import heroImg from '../images/til-hero.svg';
import ogImg from '../images/og/til.png';

const TilPage = () => {
  const parseFloatExample = `> parseFloat('25.5');
< 25.5

> parseFloat('25,5');
< 25 // parsing stops when it encounters ","
`;
  const weekDays = `const now = new Date();
const today = getDate(now);
const start = startOfWeek(now, { weekStartsOn: 1 });
const week = [];

for (let i = 0; i < 7; i++) {
  week.push(getDate(addDays(start, i)));
}
`;
  const namedGroups = `const text = 'I use gatsby@2.23.10. What about you?';
const regex = /gatsby@(?<version>\\d*.\\d*.\\d*)/;

text.match(regex)?.groups.version;
// output: 2.23.10
`;
  const smoothScroll = `html {
  scroll-behavior: smooth;
}
`;
  const scrollMarginTopCode = `[id] {
  scroll-margin-top: 50px;
}
`;

  return (
    <Layout>
      <SEO
        title="TIL"
        description="Tomek's dev diary where he takes notes of daily learnings"
        ogUrl="https://tomekdev.com/til"
        imageUrl={ogImg}
      />
      <HeroPost gradientFrom="#434343" gradientTo="#000000">
        <img src={heroImg} alt="Today I Learned" width="865" height="250" />
      </HeroPost>

      <div className="container">
        <h1 className="pageTitle">TIL!</h1>

        <section>
          <H2 noContainerClass={true}>
            parseFloat works only with a decimal point
          </H2>
          <p>
            I didn't know <code>parseFloat</code> is not aware of browser
            locale. So if you happen to live in a country where a decimal
            separator is a comma then <code>parseFloat</code> is no longer your
            friend:
          </p>
          <Code
            className="language-javascript"
            metastring=""
            noContainerClass={true}
          >
            {parseFloatExample}
          </Code>
          <p>
            Somehow I understand why... but still, that's problematic when you
            support multiple locales. I will write more about it, and how to
            overcome the issue, in one of the next posts.
          </p>
        </section>

        <section>
          <H2 noContainerClass={true}>date-fns is cool!</H2>
          <p>
            So the other day, in my sideproject, I had to implement weekdays
            calendar. It's a navigator with today selected and looks like this:
          </p>
          <CalendarViewPhoto />
          <p>
            To simplify things a bit let's just try to render: 8, 9, 10, 11, 12,
            13, 14. With regular <code>new Date()</code> it can be a nightmare
            and you'll for sure forget about something. But{' '}
            <a href="https://date-fns.org/" rel="noreferrer" target="_blank">
              date-fns
            </a>{' '}
            gives you nice tools like <code>startOfWeek</code>:
          </p>
          <Code
            className="language-javascript"
            metastring=""
            noContainerClass={true}
          >
            {weekDays}
          </Code>
          <p>
            Now, we just need to iterate through <code>week</code> array. The
            numbers are there. Waiting!
          </p>
        </section>

        <section>
          <H2 noContainerClass={true}>You can name regex groups</H2>
          <p>
            Say you have a string: "
            <em>I use gatsby@2.23.10. What about you?</em>" and you want to get
            the gatsby version from the input. If you use a regex like this and{' '}
            <code>String.match</code> you will be able to get the version really
            quickly. With a great self-explanatory code!
          </p>
          <Code
            className="language-javascript"
            metastring=""
            noContainerClass={true}
          >
            {namedGroups}
          </Code>
          <p>Named groups for the win!</p>
        </section>

        <section>
          <H2 noContainerClass={true}>
            You don't need JS for smooth scrolling
          </H2>
          <p>
            I'm still not sure if I like this effect but here's how to do it:
          </p>
          <Code className="language-css" metastring="" noContainerClass={true}>
            {smoothScroll}
          </Code>
        </section>

        <section>
          <H2 noContainerClass={true}>
            You can add a scrolling offset to anchors
          </H2>
          <p>
            When you go to a{' '}
            <Link to="/posts/anchors-for-headings-in-mdx">
              section of a page via anchor
            </Link>
            , the header is often too close to the top edge. You can add a
            little bit of space there by specifying{' '}
            <code>scroll-margin-top</code>:
          </p>
          <Code className="language-css" metastring="" noContainerClass={true}>
            {scrollMarginTopCode}
          </Code>
          You can try it by clicking here:{' '}
          <a
            href="/posts/search-with-typo-tolerance#introducing-a-tolerance"
            target="_blank"
          >
            Introducing a tolerance to searching
          </a>{' '}
          (a new tab will be opened).
        </section>
      </div>

      <p className="container mt-40">
        <small>
          Last updated: <time dateTime="2021-10-13">Oct 13, 2021</time>
        </small>
      </p>
    </Layout>
  );
};

export default TilPage;
